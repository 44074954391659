import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const meta = {
  displayName: 'Typography'
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const StorybookEmbed = makeShortcode("StorybookEmbed");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Note mdxType="Note">The following typographic styles are not yet fully integrated into Primer components or CSS utilities</Note>
    <h2>{`Typographic styles`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`Typography design tokens use `}<inlineCode parentName="p">{`rem`}</inlineCode>{` units for a more `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html"
      }}>{`accessible`}</a>{` browser zoom experience. Additionally, line height values are unitless and vary per style, making them align to the 4px grid. Each style is opinionated, as reflected in the “shorthand” tokens which control size, family, weight, and line-height with a single `}<inlineCode parentName="p">{`font`}</inlineCode>{` CSS declaration.`}</p>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'typography-functional--overview'
    }]} height="660" mdxType="StorybookEmbed" />
    <h3>{`Headings`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'typography-functional--display'
    }, {
      id: 'typography-functional--title-large'
    }, {
      id: 'typography-functional--title-medium'
    }, {
      id: 'typography-functional--title-small'
    }, {
      id: 'typography-functional--subtitle'
    }]} height="360" mdxType="StorybookEmbed" />
    <h3>{`Body`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'typography-functional--body-large'
    }, {
      id: 'typography-functional--body-medium'
    }, {
      id: 'typography-functional--body-small'
    }, {
      id: 'typography-functional--caption'
    }]} height="260" mdxType="StorybookEmbed" />
    <h3>{`Code`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'typography-functional--code-block'
    }, {
      id: 'typography-functional--inline-code-block'
    }]} height="260" mdxType="StorybookEmbed" />
    <h2>{`Font weight`}</h2>
    <p>{`Font weight options include light, normal, medium, and semibold.`}</p>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'typography-base--base'
    }]} height="260" mdxType="StorybookEmbed" />
    <h2>{`Font family`}</h2>
    <p>{`Each font stack uses system fonts, which are optimized for performance.`}</p>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'typography-functional--font-family'
    }]} height="260" mdxType="StorybookEmbed" />
    <h2>{`Best practices`}</h2>
    <h3>{`Hierarchy`}</h3>
    <p>{`When establishing hierarchy for GitHub products, designers should remember that we stress efficient, clean reading experiences for our users. Refrain from utilizing color as a primary method of emphasis.`}</p>
    <h3>{`Readability`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Line height`}</strong>{`—Utilize line-height tokens for proper alignment.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Line length`}</strong>{`—It's considered a good practice to keep lines around 80 characters or less—`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/tutorials/page-structure/styling/"
          }}>{`W3 Page Structure Guidelines`}</a>{`. Cases vary depending on the situation, but this serves as a general rule of thumb for designing layouts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Type alignment`}</strong>{`—Our content is typically left-aligned and ragged right. Be sensible in deviating from this standard, as justifying, centering, and right-aligning text is atypical of GitHub products.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Font weight`}</strong>{`—Adjust font weight to add emphasis and differentiate content hierarchy.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Letter spacing`}</strong>{`—Please refrain from altering letter-spacing on our products. There may be rare scenarios in which this is acceptable, but generally speaking, this should be avoided altogether.`}</p>
      </li>
    </ul>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/18661030/234685417-f1cbb3b6-dfdf-4594-9b96-357569aaa688.png" role="presentation" width="456" alt="A button with text in all caps" />
    <Caption mdxType="Caption">Use weight CSS variables to set font-weight</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/18661030/234685419-2f935ad2-5f78-4b23-8529-274030ea6039.png" role="presentation" width="456" alt="A button with text in all caps" />
    <Caption mdxType="Caption">Don't use arbitrary weight number values for font-weight</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/18661030/234685422-963b8278-e6d0-49c9-9943-1f781975c21a.png" role="presentation" width="456" alt="A button with text in all caps" />
    <Caption mdxType="Caption">Use semantic title markup combined with styles</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/18661030/234685425-ba616cab-d7e6-445e-9ed2-33425a4efd30.png" role="presentation" width="456" alt="A button with text in all caps" />
    <Caption mdxType="Caption">Don’t adjust semantic heading tag order to achieve a specific visual design</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/heading"
        }}>{`Heading`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/page-header"
        }}>{`PageHeader`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text"
        }}>{`Text`}</a></li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      